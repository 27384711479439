import './scss/main.scss'
import {gsap} from "gsap";
gsap.registerPlugin();
const screenHeight = () => {
    var vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const mq = window.matchMedia('(max-width: 840px)');

function init() {
    screenHeight();
    toggleMenuBehavior()
}

window.onload = (event) => {
    console.log('page is fully loaded');
    init();

};

window.addEventListener('resize', screenHeight())

function toggleMenuBehavior() {
    const menuCheckbox = document.getElementById('menu');
    const header = document.querySelector('header');

    if (menuCheckbox && header) {
        menuCheckbox.addEventListener('change', () => {
            header.classList.toggle('menu-open', menuCheckbox.checked);
            document.body.classList.toggle('overflow-hidden');
        });
    }
}

const path = document.querySelector('.outline-path');
const main = document.querySelector('.landing main');

// Combinaciones de color: se alternan ida ↔ vuelta
const colorCombos = [
    { bg: "#003E31", stroke: "#74BC56" },
    { bg: "#3B755F", stroke: "#F2CB6C" },
    { bg: "#EAEAEA", stroke: "#283470" },
    { bg: "#CE7182", stroke: "#FAEED4" },
    { bg: "#FAEED4", stroke: "#15A29C" },
    { bg: "#F2CB6C", stroke: "#3B755F" },
    { bg: "#283470", stroke: "#EAEAEA" }
];

let comboIndex = 0;
let direction = 'forward'; // controlamos si va hacia delante o atrás

// Timeline para animar el trazo
const tl = gsap.fromTo(path, {
    strokeDasharray: 1000,
    strokeDashoffset: 1000
}, {
    strokeDashoffset: 0,
    duration: 7,
    ease: "power2.inOut",
    yoyo: true,
    repeat: -1,
    repeatDelay: 0.1,
    onRepeat: () => {
        // Alternamos dirección manualmente
        direction = direction === 'forward' ? 'backward' : 'forward';

        // Avanzamos al siguiente combo
        comboIndex = (comboIndex + 1) % colorCombos.length;

        const { bg, stroke } = colorCombos[comboIndex];

        gsap.to(path, {
            stroke: stroke,
            duration: 1.4,
            ease: "power1.inOut"
        }, "-=2");

        gsap.to(main, {
            backgroundColor: bg,
            duration: 1.2,
            ease: "power1.inOut"
        });
    }
});




